<template>
    <questionCard></questionCard>
</template>

<script>
import questionCard from '../../components/questionCard.vue';

export default {
    data() {
        return {

        }
    },
    components: {
        questionCard
    }
}
</script>